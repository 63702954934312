// TODO: many files referencing this file have a "exceeded maximum budget" warning.

@use 'brand-colors' as brand;
@use 'brand-colors-mity' as brand-mity;
@use 'themes/light';
@use 'themes/dark';
@use 'themes/mity';
@use 'sass:meta';

:root {
    /* static colors (not theme-dependant) */
    --black: #{brand.$black};
    --white: #{brand.$white};
    --petrol: #{brand.$petrol};
    --green: #{brand.$green};
    --light-green: #{brand.$light-green};
    --off-white: #{brand.$off-white};
    --light-gray: #{brand.$light-gray};
    --gray: #{brand.$gray};
    --medium-gray: #{brand.$medium-gray};
    --dark-gray: #{brand.$dark-gray};
    --dark: #{brand.$dark};
    --red: #{brand.$red};
    --light-red: #{brand.$light-red};
    --yellow: #{brand.$yellow};
    --light-yellow: #{brand.$light-yellow};
    --brown: #{brand.$brown};
    --light-brown: #{brand.$light-brown};
    --blue: #{brand.$blue};
    --light-blue: #{brand.$light-blue};

    @each $name, $value in meta.module-variables('light') {
        --#{$name}: #{$value};
    }
}

:root:has(arc-root.dark) {
    @each $name, $value in meta.module-variables('dark') {
        --#{$name}: #{$value};
    }
}

:root:has(arc-root.mity) {
    /* static mity colors (not theme-dependant) */
    --black: #{brand-mity.$black};
    --white: #{brand-mity.$white};
    --petrol: #{brand-mity.$petrol};
    --green: #{brand-mity.$green};
    --light-green: #{brand-mity.$light-green};
    --off-white: #{brand-mity.$off-white};
    --light-gray: #{brand-mity.$light-gray};
    --gray: #{brand-mity.$gray};
    --medium-gray: #{brand-mity.$medium-gray};
    --dark-gray: #{brand-mity.$dark-gray};
    --dark: #{brand-mity.$dark};
    --red: #{brand-mity.$red};
    --light-red: #{brand-mity.$light-red};
    --yellow: #{brand-mity.$yellow};
    --light-yellow: #{brand-mity.$light-yellow};
    --brown: #{brand-mity.$brown};
    --light-brown: #{brand-mity.$light-brown};
    --blue: #{brand-mity.$blue};
    --light-blue: #{brand-mity.$light-blue};

    @each $name, $value in meta.module-variables('mity') {
        --#{$name}: #{$value};
    }
}

@function get-theme-variable($name) {
    @return var(--#{$name});
}

@use '@angular/material' as mat;
@use '../colors.scss';

@mixin color($theme) {
    mat-button-toggle-group {
        mat-button-toggle.mat-button-toggle {
            flex-basis: 0;
            flex-grow: 1;
        }
    }

    .mat-button-toggle-label-content {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.0892857143em;
    }

    mat-button-toggle-group.arc-button-toggle-dynamic-height {
        mat-button-toggle {
            display: flex;
        }

        .mat-button-toggle-label-content {
            line-height: unset;
        }
    }

    mat-button-toggle-group.toggle-error {
        border: 1px solid red;
    }

    /* custom button toggle styling with class 'arc-button-toggle-group' */
    mat-button-toggle-group.arc-button-toggle-group {
        border: none;
        gap: 0.5rem;
        padding: 0.5px;

        @apply smd:flex-col;

        mat-button-toggle.mat-button-toggle {
            border: 1px solid currentColor;
            border-left: 1px solid currentColor !important;

            @apply smd:rounded-[4px];

            &:first-of-type {
                border-radius: 4px 0 0 4px;
                @apply smd:rounded-[4px];
            }

            &:last-of-type {
                border-radius: 0 4px 4px 0;
                @apply smd:rounded-[4px];
            }

            &[color='success'] {
                color: colors.get-theme-variable('success');

                &.mat-button-toggle-checked {
                    background-color: colors.get-theme-variable('success-light');
                }
            }

            &[color='warn'] {
                color: colors.get-theme-variable('warn');

                &.mat-button-toggle-checked {
                    background-color: colors.get-theme-variable('warn-light');
                }
            }

            &[color='info'] {
                color: colors.get-theme-variable('info');

                &.mat-button-toggle-checked {
                    background-color: colors.get-theme-variable('info-light');
                }
            }

            &[color='error'] {
                color: colors.get-theme-variable('error');

                &.mat-button-toggle-checked {
                    background-color: colors.get-theme-variable('error-light');
                }
            }
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}

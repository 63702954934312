/* Markdown editor styles */
@use 'colors.scss';

.mde-help
{
    .cm-header-1, h1
    {
        font-size: 2rem !important;
        color: var(--accent) !important;
    }
    .cm-header-2, h2
    {
        font-size: 2rem !important;
        font-weight: 500 !important;
    }
    .cm-header-3, h3
    {
        font-size: 1.5rem !important;
        font-weight: 300 !important;
    }
}

.mde-mail
{
    .cm-header-1, h1
    {
        font-size: 2rem !important;
        color: var(--accent) !important;
    }
    .cm-header-2, h2
    {
        font-size: 2rem !important;
        font-weight: 500 !important;
        color: var(--accent) !important;
    }
    .cm-header-3, h3
    {
        font-size: 1rem !important;
        font-weight: 500 !important;
    }
}

.mde-default
{
    .cm-header-1, h1
    {
        font-size: 2rem !important;
    }
    .cm-header-2, h2
    {
        font-size: 2rem !important;
        font-weight: 500 !important;
    }
    .cm-header-3, h3
    {
        font-size: 1.5rem !important;
        font-weight: 300 !important;
    }
}

.input-error {
    .EasyMDEContainer {
        border: 1px solid red !important;
    }
}

.CodeMirror-scroll {
    min-height: 150px !important;
}

.arc-mde-editor-error .EasyMDEContainer {
    border: 1px solid colors.get-theme-variable('error') !important;
}
/* Brand colors */
$black: #0E0415;
$white: #FFFFFF;
$petrol: #004346;
$purple: #3C0E5C;
$green: #25B972;
$light-green: #7CD1A8;
$off-white: #E9E8E3;
$light-gray: #D2D2CE;
$gray: #B1B1AC;
$medium-gray: #888883;
$dark-gray: #565653;
$dark: #2F2F2D;

$red: #F23859;
$light-red: #FF9AAC;
$yellow: #F0E602;
$light-yellow: #FFFBA6;
$brown: #DDAE7E;
$light-brown: #EFDCC6;
$blue: #2825B7;
$light-blue: #A4A2F6;
